
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "LearnHowMuchSavings",
  setup() {
    /* const carProgressCircle = ref('0px');
    const bikeProgressCircle = ref('0px'); */


    /* const visibilityChanged = (isVisible: boolean) => {
      if (!isVisible) return;

      // animate the bike
      carProgressCircle.value = "164px"
      bikeProgressCircle.value = "164px"
      for (let i = 0; i < 164; i++)
      {
        setTimeout(function() {
          bikeProgressCircle.value = `${i}px 164px`
        }, 10 + 10 * i)
      }

      // animate the car
      for (let i = 0; i < 164; i++)
      {
        setTimeout(function() {
          carProgressCircle.value = `${i}px 164px`
        }, 30 + 30 * i)
      }
    }; */

    /* return {
      visibilityChanged,
      carProgressCircle,
      bikeProgressCircle
    }; */
  },
});
